$primary-color: #005eb8;
$secondary-color: #151515;
$tertiary-color: #e2101b;
// $other-color: #002147;

$rgb-primary-color: (red($primary-color), green($primary-color), blue($primary-color)); //--primary-color *
$rgb-secondary-color: (red($secondary-color), green($secondary-color), blue($secondary-color)); //--secondary-color *
$rgb-tertiary-color: (red($tertiary-color), green($tertiary-color), blue($tertiary-color)); //--tertiary-color *

@import "../../../src/styles/vars.scss";
@import "../../../src/styles/ionic.scss";
@import "../../../src/styles/tailwind.scss";
@import "../../../src/styles/styles.scss";

@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&family=Oswald:wght@200..700&display=swap');

html {
    scroll-behavior: smooth;
}
body.webapp-site {
	--font-title: 'Oswald', sans-serif;
    --font-subtext: 'Inter', sans-serif;
    --font-text: 'Inter', sans-serif;
	background-color: #f6f9f9;
    scroll-behavior: smooth;
    // General

    @import "styles/main";
    @import "styles/responsive";
}