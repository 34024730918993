cy-content ion-content {
    --background: #f6f9f9;
}

.header__custom {
    &.header__theme ion-toolbar {
        --background: var(--primary-color);
        --color: white;
    }
    .content__logo {
        overflow: hidden;
        border-bottom: 3px solid var(--primary-color);
        img {
            transform: scale(3.5) translateY(36%) translateX(28%) rotate(-20deg);
        }
    }
}