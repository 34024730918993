body.webapp-site {
  cy-main-header {
    cy-header {
      display: none;
    }
    header {
      display: block;
      .header__webapp {
        width: 100%;
        position: relative;
        nav {
          display: flex;
          align-items: center;
          justify-content: space-between;
          width: 1440px;
          max-width: 100%;
          margin: 0 auto;
          .content__left {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            &:has(.title__app) {
              .content__logo {
                display: none;
              }
            }
            ul {
              display: none;
            }
          }
          .content__right {
            display: none;
          }
        }
      }
    }
  }
  .header__custom {
    box-shadow: none;
    --background: white;
    --color: black;
    ion-toolbar {
      --background: white;
      --color: black;
      --border-width: 0;
    }

    &.header__shadow {
      box-shadow: 0 2px 4px 0px rgba(0, 0, 0, 0.1);
    }

    &.header__theme {
      --background: white;
      --color: black;
      ion-toolbar {
        --background: white;
        --color: black;
      }
      // --color: 
    }
    &.header__primary {
      --background: var(--primary-color);
      --color: white;
      ion-toolbar {
        --background: var(--primary-color);
        --color: white;
      }
      // --color: 
    }
    .header-background {
      backdrop-filter: none;
    }
    &.header__rounded {
      ion-toolbar {
        border-radius: 0 0 24px 24px;
        filter: drop-shadow(0px 0px 4px rgba(0, 0, 0, 0.08));
      }
    }
    &.transparent__toolbar {
      ion-toolbar {
        --background: transparent;
        --color: white;
      }
    }
    &.header__menu {
      --background: transparent;
      ion-toolbar {
        border-radius: 0 0 3rem 0;
        filter: drop-shadow(0px 0px 4px rgba(0, 0, 0, 0.08));
      }
      ion-buttons {
        display: none;
      }
    }

    ion-buttons {
      min-width: 48px;
      ion-button {
        --color: var(--primary-color);
        --background: white;
        --border-radius: 8px;
        i {
          font-size: 24px;
        }
      }
    }
    .content__logo {
      width: 100%;
      padding: 8px 10px;
      display: block;
      img {
        width: 70px;
        display: block;
        max-width: 100%;
        margin: 0 auto;
      }
    }

    ion-tab-bar {
      --background: transparent;
      --color: white;
      ion-tab-button {
        transition: all ease-in-out 0.25s;
        &.tab-selected {
          --background: white;
          border-radius: 10px;
        }
      }
    }

    ion-menu-button {
      font-size: 22px;
    }

    ion-searchbar {
      --border-radius: 8px;
      --background: white;
      --color: black;
      --box-shadow: none;
      padding: 8px 15px;
      .searchbar-input-container {
        border: 1px solid #ddd;
        border-radius: var(--border-radius);
      }
    }

    ion-title {
      font-size: 16px;
      &.position__start {
        text-align: left;
      }
      &.position__center {
        text-align: center;
      }
      &.position__end {
        text-align: right;
      }
    }
  }
  .footer__custom {
    box-shadow: none;
    .content__footer {
      padding: 0 10px;
    }
    &.footer__menu {
      ion-tab-bar {
        ion-tab-button {
          &.active {
            font-weight: 600;
          }
        }
      }
    }
  }
  .content__custom {
    --padding-top: 10px;
    --padding-bottom: 10px;
    --padding-start: 10px;
    --padding-end: 10px;
    &.no_pad {
      --padding-top: 0px;
      --padding-bottom: 0px;
      --padding-start: 0px;
      --padding-end: 0px;
    }
    &.no_pad-x {
      --padding-start: 0px;
      --padding-end: 0px;
    }
    &.no_pad-y {
      --padding-top: 0px;
      --padding-bottom: 0px;
    }

    &.bg__primary {
      --background: var(--primary-color);
      color: white;
      --color:white;
    }
  }

  .cy__container {
    width: var(--cy-width-container, 1440px);
    max-width: 100%;
    display: flex;
    flex-direction: column;
    min-height: 100%;
    margin: 0 auto;
    align-items: var(--cy-align-container, inherit);
    justify-content: var(--cy-justify-container, inherit);
  }

  &.cy__container-justify-center, .cy__container-justify-center {
    --cy-justify-container: center;
  }

  .content__footer-menu {
    box-shadow: none;
  }

  .cy__btn {
    width: auto;
    text-transform: none;
    --border-radius: 8px;
    font-weight: 500;
    pointer-events: auto;
    &.btn__btransparent {
      --background: rgba(255, 255, 255, 0.1);
      --color: white;
      &::part(native) {
        backdrop-filter: blur(2);
      }
    }
    &.btn__primary-transparent {
      --background: transparent; 
      --color: var(--primary-color);
      --box-shadow: none;
    }
    &.btn__second-transparent {
      --background: transparent; 
      --color: var(--secondary-color);
      --box-shadow: none;
    }
    &.btn__third-transparent {
      --background: transparent; 
      --color: var(--tertiary-color);
      --box-shadow: none;
    }
    &.btn__black-transparent {
      --background: transparent; 
      --color: black;
      --box-shadow: none;
    }
    &.btn__white-transparent {
      --background: transparent; 
      --color: white;
      --box-shadow: none;
    }
    &.btn__white {
      --background: white;
    }

    &.btn__small {
      font-size: 12px;
      --padding-start: 10px;
      --padding-end: 10px;
      --padding-top: 8px;
      --padding-bottom: 8px;
    }
    &.btn__big {
      font-size: 14px;
      --padding-start: 1em;
      --padding-end: 1em;
      --padding-top: 18px;
      --padding-bottom: 18px;
    }
    &.button-round.button-has-icon-only {
      --border-radius: 999px;
    }
    &.icon__big {
      font-size: 25px;
    }
    .inner__btn {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 5px;
    }
  }

  .content__accordion {
    p {
      font-size: calc(12px + var(--size-responsive));
    }
    ion-accordion {
      &:not(:last-child) {
        margin-bottom: 10px;
      }
      ion-item {
        --ion-color-light-shade: transparent;
        --ion-color-shade: transparent;
        --border-radius: 8px;
        ion-label {
          margin: 0;
          padding: 10px 0;
          font-size: calc(14px + var(--size-responsive));
          font-weight: 700;
        }
      }
    }
  }

  .cy__modal {
    --width: 500px;
    --height: fit-content;
    --max-width: calc(100% - (10px * 2));
    --max-height: calc(100% - (10px * 2));
    --border-radius: 12px;
    --overflow: auto;
    --background: var(--background-custom-modal, linear-gradient(156.36deg, #CEE3FF -0.73%, #FFFFFF 107.78%));
    .cy__modal-wrapper {
      .cy__modal-wrapper-btn-dismiss {
        position: absolute;
        top: 4px;
        right: 8px;
        font-size: 22px;
        min-width: auto;
        min-height: auto;
        width: 45px;
        height: 45px;
        margin: 0;
      }
      .cy__modal-wrapper-icon {
        width: 100%;
        text-align: center;
        padding: 1rem;
        display: block;
        padding-bottom: 0;
        padding-top: 3rem;
        img, i {
          display: inline-block;
          width: auto;
          max-width: 100%;
        }
        i {
          font-size: 4rem;
          line-height: normal;
        }
      }
      .cy__modal-wrapper-header {
        position: sticky;
        z-index: 1;
        top: 0;
        background-color: var(--background-custom-modal, rgba(255, 255, 255, 0.25));
        backdrop-filter: blur(4px);
        width: 100%;
        padding: 1rem;
        padding-top: 3rem;
        text-align: center;
        h3 {
          font-size: 20px;
          font-weight: 600;
        }
      }
      .cy__modal-wrapper-body {
        padding: 1rem;
      }
      &:has(.cy__modal-wrapper-header) {
        .cy__modal-wrapper-body {
          padding-top: 0;
        }
      }
      &:has(.cy__modal-wrapper-icon) {
        .cy__modal-wrapper-header {
          padding-top: 1rem;
        }
      }
    }
  }

  @media screen and (max-width: 1279px) {
    
  }

  @media screen and (max-width: 991px){
    cy-main-header {
      cy-header {
        display: contents;
      }
      header {
        display: none;
      }
    }
  }

  @media screen and (max-width: 767px) {
    
  }

  @media screen and (max-width: 639px) {
    
  }

  @media screen and (max-width: 512px){
    .header__custom .content__logo img {
      width: auto;
      height: 50px;
    }
  }

  @media screen and (min-width: 992px) {
    .header__custom {
      ion-menu-button {
        display: none;
      }
      ion-toolbar .header__webapp nav {
        .content__left {
          width: auto;
          gap: 10px;
          justify-content: flex-start;
          ul {
            display: flex;
            align-items: center;
            gap: 10px;
          }
          ion-title.title__app {
            display: none;
          }
        }
        .content__right {
          display: flex;
          align-items: center;
          gap: 10px;
        }
      }
    }
    .footer__menu {
      display: none;
    }
  }

  @media screen and (min-width: 1280px) {
    
  }

  @media screen and (min-width: 1350px) {
    
  }

  @media screen and (min-width: 1536px) {
    
  }
}