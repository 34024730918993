app-root {
    display: contents;
    ion-app {
        ion-router-outlet {
            position: relative;
            flex: 1;
            width: 100%;
        }
    }
}
.container_cy {
    width: 1440px;
    max-width: 100%;
    display: block;
    margin-left: auto;
    margin-right: auto;
    padding: 0 1rem;
    &.no__pad {
        padding: 0;
    }
}
.text-two-hidden {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
    overflow: hidden;
}
template-1,
template-2,
template-3,
template-4,
template-5,
app-sidebar,
app-sidebar-documentation,
button-cy {
    display: contents;
}

.content__center-column {
    width: 100%;
    min-height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.page_introduction {
    color: white;
    --color: white;
    --background: transparent;
    text-align: left;
    h5 {
        font-size: calc(12px + var(--size-responsive));
        line-height: calc(18px + var(--size-responsive));
    }
    h2 {
        font-size: calc(24px + var(--size-responsive));
        line-height: calc(24px + var(--size-responsive));
    }
    p {
        font-size: calc(12px + var(--size-responsive));
        line-height: calc(18px + var(--size-responsive));
    }
    h2,
    h5,
    p {
        margin: 0 0 10px 0;
        text-shadow: 2px 3px rgba(0, 0, 0, 0.341);
    }
}

.animation__updown {
    animation: updown 2s ease infinite;
}

@keyframes updown {
    0% {
        transform: translateY(-15%);
    }
    
    50% {
        transform: translateY(15%);
    }
    
    100% {
        transform: translateY(-15%);
    }
}

.bg__primary {
    background-color: var(--primary-color);
}
.bg__second {
    background-color: var(--secondary-color);
}
.bg__third {
    background-color: var(--tertiary-color);
}

.text__primary {
    color: var(--primary-color);
}
.text__second {
    color: var(--secondary-color);
}
.text__third {
    color: var(--tertiary-color);
}

.btn__down {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    margin: 0 auto;
    width: fit-content;
    text-align: center;
    button {
        padding: 1rem;
        appearance: none;
        border: 0;
        background-color: transparent;
        cursor: pointer;
        display: block;
        margin: 0;
    }
    h5 {
        font-size: calc(12px + var(--size-responsive));
        line-height: calc(18px + var(--size-responsive));
        margin: 0 0 10px 0;
        text-decoration: underline;
        font-weight: 400;
    }
    img {
        display: block;
        width: auto;
        margin: 0 auto;
    }
}

.content__pad {
    padding: 0.5rem;
    &.not_x {
        padding-left: 0px;
        padding-right: 0px;
    }
    &.not_y {
        padding-top: 0px;
        padding-bottom: 0px;
    }
}

.content__next-match-home {
    display: flex;
    padding: 1rem;
    background-color: white;
    border-radius: 8px;
    p {
        font-size: calc(12px + var(--size-responsive));
        line-height: calc(18px + var(--size-responsive));
        margin: 0;
    }
    h3 {
        font-size: calc(30px + var(--size-responsive));
        line-height: 1;
        font-weight: 700;
        font-family: var(--font-title);
        margin: 0 0 3px 0;
    }
    h5 {
        font-size: calc(14px + var(--size-responsive));
        line-height: calc(21px + var(--size-responsive));
        font-weight: 700;
        margin: 0;
    }
    .content__left {
        width: 95px;
        text-align: center;
    }
    .content__right {
        flex: 1;
        text-align: left;
        padding-left: 10px;
    }
}

.content__carousel-home {
    position: relative;
    width: 100%;
    --swiper-pagination-bullet-inactive-color: white;
    --swiper-pagination-color: white;
    .content__carousel-trajectory {
        position: relative;
        // padding: 1rem;
        background: linear-gradient(90deg, #030f27 0%, #192745 100%);
        img {
            display: block;
            width: auto;
            margin: 0 auto;
        }
    }
    .content__carousel {
        position: relative;
        background-size: cover;
        background-position: center;
        color: white;
        aspect-ratio: 6 / 5;
        .bg__black {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: rgba(0, 0, 0, 0.6);
        }
        img,
        .content__carousel-detail {
            position: relative;
        }
        img {
            display: block;
            width: auto;
            margin: 0 auto;
            padding: 3rem 0 0;
            &.trajectory_img {
                padding: 1rem;
            }
        }
        .content__carousel-detail {
            font-size: calc(12px + var(--size-responsive));
            line-height: calc(18px + var(--size-responsive));
            padding: 1rem;
            p {
                font-size: calc(12px + var(--size-responsive));
                line-height: calc(18px + var(--size-responsive));
                margin: 0;
            }
        }
    }
    .btns__swiper {
        position: absolute;
        top: 0;
        bottom: 0;
        margin: auto 0;
        height: fit-content;
        width: fit-content;
        z-index: 2;
        &.btn__prev {
            left: 10px;
        }
        &.btn__next {
            right: 10px;
        }
    }
    swiper-container {
        &::part(pagination) {
            text-align: right;
            right: 10px;
            left: 10px;
            bottom: 0;
            width: calc(100% - (10px * 2));
        }
    }
}

.content__title-section {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
    margin-bottom: 5px;
    h4 {
        font-size: calc(18px + var(--size-responsive));
        font-weight: 600;
        line-height: calc(27px + var(--size-responsive));
        font-family: var(--font-title);
        width: auto;
        display: block;
        margin: 0;
    }
}

.content__media {
    position: relative;
    width: 100%;
    .content__media-item {
        aspect-ratio: 1 / 1.5;
        position: relative;
        background-size: cover;
        background-position: center;
        border-radius: 16px;
        overflow: hidden;
    }
}

.content__news {
    position: relative;
    width: 100%;
    background-color: transparent;
    overflow: hidden;
    border-radius: 8px;
    .content__news-item {
        width: 100%;
        display: flex;
        align-items: stretch;
        &:not(:last-child) {
            margin-bottom: 10px;
        }
        .content__news-item-img {
            width: 76px;
            background-size: cover;
            background-position: center;
            background-repeat: no-repeat;
        }
        .content__news-item-detail {
            flex: 1;
            padding: 0.85rem;
            color: var(--primary-color);
            background-color: white;
            p {
                font-size: calc(12px + var(--size-responsive));
                line-height: calc(18px + var(--size-responsive));
                margin: 0;
                &.date {
                    color: rgba(0, 0, 0, 0.5);
                }
            }
            h5 {
                font-size: calc(16px + var(--size-responsive));
                line-height: normal;
                font-weight: 700;
                margin: 0;
            }
        }
    }
}

.content__players {
    position: relative;
    width: 100%;
    .content__player {
        width: 100%;
        background-color: white;
        border-radius: 8px;
        overflow: hidden;
        padding: 8px;
        .content__player-item {
            width: 100%;
            display: flex;
            .content__player-item-img {
                width: 56px;
                height: 85px;
                background-size: contain;
                background-position: center;
                background-repeat: no-repeat;
            }
            .content__player-item-detail {
                flex: 1;
                padding: 0.85rem;
                color: var(--primary-color);
                p {
                    font-size: calc(12px + var(--size-responsive));
                    line-height: calc(18px + var(--size-responsive));
                    margin: 0;
                }
                h5 {
                    font-size: calc(16px + var(--size-responsive));
                    line-height: normal;
                    font-weight: 700;
                    margin: 0;
                }
            }
        }
        .content__player-divider {
            width: calc(100% - (10px * 2));
            display: block;
            margin: 0 auto;
            height: 2px;
            background-color: #ddd;
        }
        .content__player-info {
            display: flex;
            flex-wrap: wrap;
            .content__player-info-data {
                flex: 1;
                text-align: center;
                padding: 0.5rem;
                p {
                    font-size: calc(12px + var(--size-responsive));
                    line-height: calc(18px + var(--size-responsive));
                    margin: 0;
                    font-weight: 400;
                }
                h5 {
                    font-size: calc(18px + var(--size-responsive));
                    line-height: calc(23.4px + var(--size-responsive));
                    margin: 0;
                    font-weight: 700;
                    color: var(--primary-color);
                }
            }
        }
        .content__player-btn {
            text-align: center;
        }
    }
}

.content__benefits {
    position: relative;
    width: 100%;
    background-color: #010511;
    .content__benefits-banner {
        width: 100%;
        position: relative;
        &::after {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            z-index: 1;
            background: linear-gradient(
            180deg,
            rgba(0, 0, 0, 0) 55.44%,
            #010511 93.52%
            );
        }
        img {
            width: auto;
            max-width: 100%;
            display: block;
            margin: 0 auto;
        }
    }
    .content__benefits-grid {
        width: 100%;
        display: grid;
        grid-template-columns: repeat(2, minmax(0, 1fr));
        gap: 1rem;
        padding: 0.5rem;
        .content__benefits-grid-item {
            width: 100%;
            border-radius: 8px;
            overflow: hidden;
            img {
                width: auto;
                max-width: 100%;
                display: block;
                margin: 0 auto;
                border-radius: 8px;
            }
        }
    }
}

.content__videos {
    position: relative;
    width: 100%;
    .content__video {
        position: relative;
        width: 100%;
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        aspect-ratio: 1 / 0.65;
        display: flex;
        align-items: flex-end;
        color: white;
        border-radius: 8px;
        overflow: hidden;
        &.default__logo {
            background-size: auto;
            .content__video-play {
                background: rgba(var(--rgb-primary-color), 0.7);
            }
        }
        .content__video-play {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            z-index: 1;
            background: linear-gradient(
            180deg,
            var(--primary-color) 0%,
            rgba(var(--rgb-primary-color), 0.2) 50%,
            var(--primary-color) 100%
            );
            display: flex;
            align-items: center;
            justify-content: center;
            img {
                width: auto;
                display: block;
                max-width: 100%;
            }
        }
        .content__video-info {
            position: relative;
            z-index: 2;
            padding: 0.8rem;
            height: 100%;
            width: 100%;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            p,
            .span_text {
                font-size: calc(12px + var(--size-responsive));
                line-height: calc(18px + var(--size-responsive));
                font-weight: 500;
                display: block;
                text-shadow: 0 0 black;
            }
            h6 {
                font-size: 14px;
                margin: 10px 0 0 0;
                small {
                    color: #ddd;
                }
                .badge {
                    border-radius: 6px;
                }
            }
        }
    }
}

.content__next-matchs {
    position: relative;
    width: 100%;
    .content__next-match {
        position: relative;
        width: 100%;
        padding: 1rem;
        padding-bottom: 2rem;
        background: white;
        border-radius: 8px;
        h5,
        p {
            font-size: calc(12px + var(--size-responsive));
            line-height: calc(18px + var(--size-responsive));
            color: black;
        }
        h4 {
            font-size: calc(14px + var(--size-responsive));
            line-height: calc(21px + var(--size-responsive));
            font-weight: 600;
            color: black;
        }
        h5 {
            color: var(--primary-color);
            font-weight: 600;
        }
        .content__next-match-teams {
            width: 100%;
            display: flex;
            align-items: stretch;
            text-align: center;
            padding-bottom: 10px;
            .content__next-match-team-a,
            .content__next-match-team-b {
                flex: 1;
                display: flex;
                flex-direction: column;
                .content__next-match-team-img {
                    flex: 1;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    margin-bottom: 10px;
                }
            }
            .content__next-match-team-vs {
                align-self: center;
                padding: 0.5rem;
                font-size: calc(29px + var(--size-responsive));
                font-weight: 700;
                line-height: normal;
            }
        }
        .content__next-match-divider {
            width: calc(100% - (10px * 2));
            display: block;
            margin: 0 auto;
            height: 2px;
            background-color: #ddd;
        }
        .content__next-match-detail {
            display: flex;
            .content__next-match-detail-data {
                padding: 0.5rem;
                flex: 1;
            }
        }
    }
}

.content__event {
    width: 100%;
    display: block;
    position: relative;
    .content__event-img {
        width: 100%;
        display: block;
        img {
            width: auto;
            max-width: 100%;
            margin: 0 auto;
            display: block;
        }
    }
    .content__event-detail {
        width: 100%;
        display: block;
        h4 {
            font-size: calc(14px + var(--size-responsive));
            line-height: calc(21px + var(--size-responsive));
            font-weight: 600;
            color: black;
        }
        h5,
        p {
            font-size: calc(12px + var(--size-responsive));
            line-height: calc(18px + var(--size-responsive));
            color: black;
            margin: 0;
            display: block;
        }
        h5 {
            color: var(--primary-color);
            font-weight: 600;
        }
    }
}

.featured__product-home-grid {
    display: block;
    width: 100%;
}

.featured__product-home {
    width: 100%;
    display: block;
    border-radius: 8px;
    overflow: hidden;
    background-color: var(--primary-color);
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    img {
        width: auto;
        max-width: 100%;
        display: block;
        // margin: 0 auto;
    }
    .featured__product-detail {
        padding: 0.8rem;
        width: 100%;
        color: white;
        background-color: rgba(0, 0, 0, 0.4);
        max-width: 100%;
        h5 {
            font-size: calc(20px + var(--size-responsive));
            line-height: calc(30px + var(--size-responsive));
            font-weight: 700;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            width: 100%;
        }
        p {
            font-size: calc(16px + var(--size-responsive));
            line-height: calc(24px + var(--size-responsive));
            font-weight: 400;
        }
        .featured__product-detail-btn {
            text-align: right;
        }
    }
}

.badge {
    font-size: calc(12px + var(--size-responsive));
    line-height: calc(18px + var(--size-responsive));
    text-align: center;
    border-radius: 3rem;
    padding: 5px 8px;
    background-color: var(--primary-color);
    color: white;
    vertical-align: middle;
    &.badge__white {
        background-color: white;
        color: var(--primary-color);
    }
    &.badge__bwhite {
        background-color: rgba(255, 255, 255, 0.2);
        backdrop-filter: blur(4px);
        color: white;
    }
    .icon_star {
        color: #ffb73a;
    }
}

.content__field {
    text-align: left;
    width: 100%;
    display: block;
    margin-bottom: 0.8rem;
    &.no__mbottom {
        margin-bottom: 0;
    }
    .custom__label {
        color: var(--primary-color);
        font-size: 14px;
        font-weight: 500;
        line-height: normal;
    }
    ion-checkbox {
        width: auto;
        max-width: 100%;
        font-size: 14px;
        line-height: normal;
        color: var(--primary-color);
        &::part(label) {
            margin-inline-start: 10px;
            text-overflow: unset;
            white-space: normal;
            overflow: unset;
        }
    }
    ion-item {
        --padding-start: 0px;
        --inner-padding-end: 0px;
        --inner-border-width: 0px;
        ion-input {
            border: 0;
            &.input-fill-outline {
                min-height: 46px;
                --ion-color-step-300: #d1d5d8;
                --border-radius: 8px;
            }
        }
        ion-textarea {
            textarea {
                outline: none !important;
                border: none !important;
                box-shadow: none;
            }
        }
    }
    ion-datetime-button {
        width: 100%;
        display: block;
        &::part(native) {
            width: 100%;
            background: transparent;
            border: 1px solid #d1d5d8;
            padding: 10px 15px;
            margin: 0;
            text-align: left;
        }
    }
    
    &:has(.custom__label) {
        ion-select {
            border: var(--highlight-height, 1px) solid #d1d5d8;
            border-radius: 8px;
            min-height: 48px;
            --background: transparent;
            --min-height: 46px;
            --border-width: 0;
            --highlight-height: 1px;
            overflow: hidden;
            &::part(label) {
                display: none;
            }
            &.ion-touched {
                border-color: var(--highlight-color);
            }
            &.select-expanded {
                --highlight-height: 2px;
            }
        }
    }
    .content__select-div {
        width: auto;
        display: block;
        border: 1px solid #d1d5d8;
        border-radius: 8px;
        min-height: 48px;
        line-height: normal;
        font-size: 16px;
        padding: 12px 15px;
        cursor: pointer;
    }
    
    .input__date {
        input.input__date-item {
            background: transparent;
            border: 1px solid #d1d5d8;
            border-radius: 8px;
            min-height: 48px;
        }
    }
    
    .input__image,
    .input__file {
        width: auto;
        display: block;
        .content__file-input .btn__image-change {
            width: auto;
            display: flex;
            align-items: center;
            justify-content: center;
            text-align: center;
            gap: 10px;
            border: 1px solid #d1d5d8;
            border-radius: 8px;
            min-height: 48px;
            line-height: normal;
            font-size: 16px;
            padding: 12px 15px;
            cursor: pointer;
            span {
                word-break: break-word;
                text-align: left;
            }
        }
        .input__image-content {
            padding: 10px;
            width: 100%;
            display: block;
            .input__image-preview {
                width: 100%;
                display: block;
                border-radius: 10px;
                &:has(img) {
                    padding: 10px;
                    border: 2px dashed #ddd;
                    margin-bottom: 10px;
                }
                img {
                    display: block;
                    width: auto;
                    max-width: 100%;
                    margin: 0 auto;
                    max-height: 300px;
                }
            }
        }
    }
}

ion-datetime {
    &::part(wheel-item active) {
        background-color: rgba(var(--rgb-primary-color), 0.2);
        border-radius: 10px;
        padding-inline: 10px;
        transition: all ease-in 0.2s;
    }
}

.a__link {
    font-size: 14px;
    font-weight: 600;
    line-height: normal;
    color: var(--primary-color);
    cursor: pointer;
    &.type__underline {
        text-decoration: underline;
    }
}

.auth__text {
    font-size: 14px;
    font-weight: 400;
    line-height: normal;
    color: #6b7280;
}

.content__custom.page_auth {
    --background: var(--primary-color);
}

.content__form {
    width: 800px;
    max-width: 100%;
    margin: 0 auto;
    background: white;
    border-radius: 16px;
    padding: 1rem;
    .content__form-wrapper {
        width: 600px;
        max-width: 100%;
        display: block;
        margin: 0 auto;
    }
    .content__form-header {
        img {
            display: block;
            margin: 0 auto;
            width: 156px;
            max-width: 100%;
            max-height: 150px;
        }
        h3 {
            text-align: left;
            font-size: calc(20px + var(--size-responsive));
            line-height: normal;
            font-weight: 500;
            color: var(--secondary-color);
        }
    }
}

.content__external-auth {
    width: 100%;
    padding: 1rem 0;
}

.slide-space__down {
    &::part(container) {
        padding-top: 0.5rem;
        padding-bottom: 2rem;
    }
    .content__image {
        width: 100%;
        aspect-ratio: 1 / 0.6;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
    }
}

.content__searchbar {
    display: flex;
    align-items: center;
    width: 100%;
    background: white;
    border-radius: 8px;
    overflow: hidden;
    input {
        flex: 1;
        appearance: none;
        border: none;
        padding: 10px 15px;
        font-size: 14px;
    }
    ion-button {
        border-left: 1px solid #c1c1c1;
    }
}

.overflow__x {
    overflow: hidden;
    overflow-x: auto;
    max-width: 100%;
    width: 100%;
}

.overflow__y {
    overflow: hidden;
    overflow-y: auto;
    max-width: 100%;
    width: 100%;
}

.align__center {
    align-items: center;
}
.justify__center {
    justify-content: center;
}

.content__profiles {
    display: inline-flex;
    gap: var(--space-items-profile, 10px);
    .profile__item {
        width: 100px;
        position: relative;
        border-radius: 8px;
        overflow: hidden;
        &.square__item {
            aspect-ratio: 1 / 1;
        }
        &.add__profile {
            position: sticky;
            right: 0;
            top: 0;
            background-color: white;
            display: flex;
            align-items: center;
            justify-content: center;
            border: 2px dashed var(--primary-color);
            color: var(--primary-color);
            font-size: calc(38px + var(--size-responsive));
            cursor: pointer;
        }
        .profile__item-image {
            position: relative;
            width: 100%;
            aspect-ratio: 1 / 1;
            background-color: #e3e3e3;
            img {
                width: 100%;
                height: 100%;
                display: block;
                object-fit: cover;
                object-position: center;
            }
        }
        .profile__item-name {
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            padding: 5px 10px;
            background-color: rgba(255, 255, 255, 0.2);
            backdrop-filter: blur(4px);
            h5 {
                font-size: calc(12px + var(--size-responsive));
                line-height: normal;
                font-weight: 600;
                color: black;
                margin: 0;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                width: 100%;
                text-align: center;
            }
        }
    }
}

.ct__divider {
    width: calc(100% - (10px * 2));
    margin: 10px;
    background-color: #e6e6e6;
    height: 1px;
    &.divider_complete {
        width: 100%;
        margin: 10px 0;
    }
}

.card__content {
    width: 100%;
    background-color: white;
    border-radius: 8px;
    padding: 1rem;
    position: relative;
    &.card__not {
        background-color: transparent;
        border-radius: 0;
    }
    &.card__not-space {
        background-color: transparent;
        border-radius: 0;
        padding: 0;
        .content__int-card {
            background-color: white;
            border-radius: 8px;
            padding: 10px;
        }
    }
    &.card__column {
        background-color: transparent;
        border-radius: 0;
        padding: 0;
        text-align: left;
        .content__int-card {
            flex-direction: column;
            background-color: white;
            border-radius: 8px;
            padding: 10px;
            &.type__discount-product {
                .content__img {
                    width: 100%;
                    opacity: 1;
                }
                .content__detail {
                    margin-top: 0;
                    padding-top: 0;
                }
            }
            .content__img {
                width: 60%;
                margin: 0 auto 0.8rem;
                aspect-ratio: auto;
                opacity: 0.1;
                img {
                    height: auto;
                    aspect-ratio: 1 / 1;
                }
            }
            .content__detail {
                padding: 0;
                padding-top: 0.5rem;
                margin-top: -3rem;
                position: relative;
                h4 {
                    display: -moz-box;
                    display: -webkit-box;
                    line-clamp: 2;
                    -webkit-line-clamp: 2;
                    -webkit-box-orient: vertical;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    min-height: 40px;
                    line-height: 20px;
                }
            }
        }
    }
    h4 {
        font-size: 16px;
        line-height: normal;
        font-weight: 600;
        margin: 0;
    }
    p {
        font-size: calc(12px + var(--size-responsive));
        line-height: calc(18px + var(--size-responsive));
        font-weight: 400;
        margin: 0;
    }
    .content__int-card {
        display: flex;
        align-items: center;
        .content__img {
            width: 96px;
            aspect-ratio: 1 / 1;
            position: relative;
            &.small__img {
                width: 80px;
            }
            &.supersmall__img {
                width: 50px;
            }
            img {
                display: block;
                width: 100%;
                height: 100%;
                object-fit: scale-down;
                object-position: center;
                &.rounded__img {
                    border-radius: 8px;
                }
            }
        }
        .content__detail {
            flex: 1;
            padding-left: 8px;
            .content__detail-price {
                font-size: 16px;
                font-weight: 600;
                line-height: normal;
                color: var(--error-color);
                margin: 0;
                span {
                    vertical-align: middle;
                }
                del {
                    vertical-align: middle;
                    color: black;
                    opacity: 0.5;
                    font-size: 70%;
                    margin: 5px;
                }
                .badge {
                    vertical-align: middle;
                    background-color: var(--error-color);
                }
            }
        }
        .content__detail-edit {
            position: absolute;
            top: 0;
            right: 2px;
        }
    }
    
    ion-avatar {
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 1.5rem;
        background-color: rgba(var(--rgb-primary-color), 0.1);
        width: 50px;
        height: auto;
        aspect-ratio: 1 / 1;
    }
    h6 {
        font-size: 14px;
        line-height: 1.4;
        font-weight: 600;
    }
    .btn__card {
        --background: rgba(var(--rgb-primary-color), 0.1);
    }
}

.divider__y {
    height: 8px;
    width: 100%;
    display: block;
}

.bg__video {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    background-color: black;
    video {
        width: 100%;
        height: 100%;
        opacity: 0.6;
        // object-fit: contain;
        // object-fit: scale-down;
        object-fit: cover;
        &.hide_video {
            opacity: 0;
        }
    }
    .poster {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: flex;
        background-color: black;
        z-index: -1;
        opacity: 0.6;
        img {
            display: block;
            margin: auto;
        }
    }
}

.content__membership-benefits {
    width: 100%;
    position: relative;
    &.type__list {
        .content__benefit:not(:last-child) {
            margin-bottom: 10px;
        }
    }
    .content__benefit {
        width: 100%;
        display: block;
        border-radius: 8px;
        background-color: white;
        color: black;
        padding: 1rem;
        .content__benefit-header {
            width: 100%;
            img {
                width: auto;
                max-width: 100%;
                display: block;
                margin: 0 auto;
            }
            h4 {
                font-size: calc(18px + var(--size-responsive));
                line-height: normal;
                font-weight: 700;
                text-align: center;
                margin: 10px 0;
            }
        }
        .content__benefit-list {
            width: 100%;
            display: block;
            padding-left: 1rem;
            li {
                list-style-image: url("/global/assets/img/icons/checkbox.svg");
                padding-inline-start: 5px;
                font-size: calc(12px + var(--size-responsive));
            }
        }
        .content__benefit-price {
            text-align: center;
            width: 100%;
            display: block;
            h5 {
                margin: 0;
                font-size: 20px;
                font-weight: 700;
                color: var(--secondary-color);
            }
        }
    }
}

.content__header-menu {
    position: relative;
    .header__custom.header__menu {
        ion-toolbar {
            --background: var(--primary-color);
            border-radius: 0 0 7rem 0;
            .content__header-menu-logo {
                padding: 3rem 1.5rem 4rem;
                color: white;
                h3 {
                    font-size: 28px;
                    font-weight: 700;
                }
            }
        }
    }
    .img__logo {
        position: absolute;
        bottom: 0;
        right: 0;
        transform: translate(-36%, 50%);
        width: 80px;
        height: 80px;
        object-fit: contain;
        object-position: center;
        z-index: 11;
    }
}

.userCustomTitle {
    text-transform: none;
    color: #2d3748;
    font-size: 1.125rem;
    padding: 0.5rem 1rem;
    display: inline-flex;
    align-items: center;
    position: relative;
    font-family: var(--font-title);
    
    &::before {
        content: "";
        width: 100%;
        height: 100%;
        transition: all 0.3s ease-in-out;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%) scale(0.5);
        background-color: #f7fafc;
        border-radius: 1.5rem;
        z-index: -1;
        opacity: 0;
    }
    
    &:hover::before {
        opacity: 1;
        transform: translate(-50%, -50%) scale(1);
    }
    .userCustomTitle-img {
        width: 40px;
        height: 40px;
    }
    .userCustomTitle-detail {
        display: none;
        max-width: 115px;
        span {
            text-transform: uppercase;
            font-size: 0.875rem;
            display: block;
            text-align: left;
            line-height: normal;
            font-weight: 600;
            overflow: hidden;
            max-width: 100%;
            white-space: nowrap;
            
            &::after {
                content: "";
                text-overflow: ellipsis;
            }
        }
    }
}

.dropdown_menu_cy-item {
    position: relative;
    .dropdown_menu_cy-action {
        text-transform: uppercase;
        color: var(--secondary-color);
    }
    &.is_open {
        .dropdown_menu_cy-action {
            color: var(--primary-color);
        }
        .dropdown_menu_cy-content {
            z-index: 1;
        }
    }
    &.is_closed {
        .dropdown_menu_cy-content {
            transition: opacity ease-in-out 0.15s, visibility ease-in-out 0.15s;
            opacity: 0;
            visibility: hidden;
        }
    }
    .dropdown_menu_cy-content {
        position: absolute;
        top: calc(100% + 40px);
        min-width: 300px;
        &.position__center {
            left: 50%;
            transform: translateX(-50%);
        }
        &.position__left {
            left: 0;
        }
        &.position__right {
            right: 0;
        }
        .dropdown_menu_cy-content-int {
            position: relative;
            width: 100%;
            padding: 0.5rem;
            background-color: #fff;
            color: var(--secondary-color);
            border-radius: 10px;
            box-shadow: 1px -5px 6px 0px rgba(15, 24, 41, 0.031),
            0px 12px 16px -4px rgba(15, 24, 41, 0.078);
            max-height: 500px;
            overflow: auto;
        }
    }
    &.cart__position {
        &:has(.position__center) {
            .dropdown_menu_cy-content.position__center {
                left: calc(50% - 52px);
            }
        }
        &:has(.position__right) {
            .dropdown_menu_cy-content.position__right {
                right: -52px;
            }
        }
    }
}

.content__footer-menu-user {
    padding: 10px 15px;
    width: 100%;
    display: block;
    ion-item {
        --border-width: 0px;
        --inner-border-width: 0px;
        --inner-padding-end: 5px;
        border: 1px solid #ddd;
        border-radius: 10px;
        .content__detail {
            display: block;
            width: 100%;
        }
        p {
            font-size: 14px;
            text-align: left;
            line-height: normal;
            margin: 0;
            display: block;
            word-break: break-word;
            font-weight: 600;
            small {
                font-weight: 400;
            }
        }
    }
}

.noauth__footer {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    padding: 0 10px;
    .cy__btn {
        font-size: 12px;
    }
}

.content__body-menu ion-item {
    --border-radius: 8px;
    ion-icon,
    img,
    i {
        margin-inline-end: var(--padding-start);
        width: 24px;
        color: black;
        opacity: 0.8;
    }
    i {
        font-size: 1.4rem;
        text-align: center;
    }
}
.content__loading {
    width: 100%;
    display: block;
    text-align: center;
}

.loader__page {
    width: 40px;
    height: 40px;
    position: relative;
    margin: auto;
    &.loader_one {
        &::before,
        &::after {
            content: "";
            position: absolute;
            border-radius: 50%;
            width: 100%;
            height: 100%;
            top: calc(50% - 15px);
            left: calc(50% - 15px);
            animation-duration: 3s;
            animation-timing-function: linear;
            animation-iteration-count: infinite;
        }
        &::before {
            border-style: solid;
            border-width: 3px 3px 3px 0;
            border-color: var(--primary-color) transparent transparent;
            transform: scale(0.3) rotate(0deg);
            opacity: 0.5;
            animation-name: foregroundCircle;
        }
        &::after {
            background: var(--primary-color);
            opacity: 0.5;
            transform: scale(0);
            animation-name: backgroundCircle;
        }
    }
}
@keyframes foregroundCircle {
    0% {
        transform: scale(0.3) rotate(0deg);
    }
    12.5% {
        transform: scale(0.3) rotate(180deg);
    }
    25%,
    50% {
        opacity: 1;
    }
    50% {
        transform: scale(1) rotate(720deg);
    }
    100% {
        transform: scale(0.3) rotate(1800deg);
        opacity: 0.5;
    }
}
@keyframes backgroundCircle {
    12.5% {
        transform: scale(0.3);
    }
    90%,
    100% {
        transform: scale(2);
        opacity: 0;
    }
}

.content__video-player {
    width: 100%;
    aspect-ratio: 3 / 2;
    position: relative;
    contain: layout style paint;
    vg-player {
        .content__video-player-bg {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: rgba(0, 0, 0, 0.45);
            z-index: 2;
            transition: all ease 0.4s;
        }
        &.controls-hidden {
            .vg-overlay-play .overlay-play-container:before,
            .alterTime-custom {
                opacity: 0;
            }
            .content__video-player-bg {
                opacity: 0;
                visibility: hidden;
                pointer-events: none;
            }
        }
        .vg-overlay-play {
            width: auto;
            height: auto;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            opacity: 1;
            .overlay-play-container {
                width: 60px;
                height: 60px;
                position: unset;
                background-color: rgba(0, 0, 0, 0.6);
                border-radius: 50%;
                &:before {
                    content: "";
                    background-color: #fff;
                    mask-image: url("/global/assets/img/icons/pause-icon.svg");
                    -webkit-mask-image: url("/global/assets/img/icons/pause-icon.svg");
                    width: 60px;
                    height: 60px;
                    display: block;
                    opacity: 1;
                    transform: scale(0.8) !important;
                    transform-origin: center;
                }
                &.vg-icon-play_arrow:before {
                    mask-image: url("/global/assets/img/icons/play-icon.svg");
                    -webkit-mask-image: url("/global/assets/img/icons/play-icon.svg");
                    transform: scale(0.8) translateX(4px) !important;
                }
            }
            &.controls-hidden {
                opacity: 0;
            }
        }
        &.native-fullscreen {
            .alterTime-custom {
                display: block;
            }
        }
        .alterTime-custom {
            opacity: 1;
            color: white;
            .alterTime {
                position: absolute;
                top: 50%;
                transform: translate(-50%, -50%);
                z-index: 200;
                font-size: 42px;
                width: 60px;
                height: 60px;
                text-align: center;
                border-radius: 50%;
                background-color: rgba(0, 0, 0, 0.6);
                cursor: pointer;
                &.minus-alterTime {
                    left: 25%;
                    i:after {
                        left: 18px;
                    }
                    &:active i {
                        transform: translateY(3.4px) rotate(-60deg);
                        &:after {
                            transform: rotate(60deg);
                        }
                    }
                }
                &.plus-alterTime {
                    right: 25%;
                    transform: translate(50%, -50%);
                    i:after {
                        right: 18px;
                    }
                    &:active i {
                        transform: translateY(3.4px) rotate(60deg);
                        &:after {
                            transform: rotate(-60deg);
                        }
                    }
                }
                i {
                    line-height: 60px;
                    position: relative;
                    display: inline-block;
                    transition: transform cubic-bezier(0.68, -0.04, 0.51, 0.85) 0.25s;
                    transform-origin: center;
                    &:after {
                        content: attr(altertime);
                        font-size: 14px;
                        position: absolute;
                        top: 24px;
                        line-height: normal;
                        display: inline-block;
                        transition: transform cubic-bezier(0.68, -0.04, 0.51, 0.85) 0.25s;
                    }
                }
            }
        }
        .statusbar_content {
            margin: auto 10px;
            transition: unset;
            height: 3px;
            vg-scrub-bar-current-time {
                height: 3px;
                position: relative;
                top: 0;
                .background {
                    position: relative;
                    border: 0;
                    border-radius: none;
                    &:before {
                        content: "";
                        position: absolute;
                        transform: translateY(-50%);
                        top: 50%;
                        right: -10px;
                        width: 20px;
                        height: 20px;
                        background-color: var(--secondary-color);
                        border-radius: 50%;
                        z-index: 555;
                    }
                }
            }
            vg-scrub-bar-buffering-time {
                height: 3px;
                top: 0;
            }
        }
        vg-controls {
            bottom: 6.5%;
            background-color: transparent;
            padding: 0 15px;
            font-size: 12px;
            transition: unset;
            &.hide {
                bottom: 6.5%;
                opacity: 0;
            }
            vg-time-display {
                pointer-events: none !important;
            }
            vg-fullscreen {
                position: fixed;
                top: 0;
                right: 0;
                .vg-icon-fullscreen,
                .vg-icon-fullscreen_exit {
                    position: relative;
                    color: black;
                    &::after {
                        content: "";
                        position: absolute;
                        top: 0;
                        left: 0;
                        right: 0;
                        bottom: 0;
                        margin: auto;
                        width: 80%;
                        height: 80%;
                        background-color: white;
                        border-radius: 8px;
                        z-index: -1;
                    }
                }
            }
        }
        .vg-buffering .bufferingContainer {
            margin: 0;
            transform: translateY(-50%);
        }
    }
}

.content__segment-wrapper {
    width: 100%;
    min-height: 100%;
    display: flex;
    flex-direction: column;
    .content__segment-header {
        position: sticky;
        top: 0;
        z-index: 2;
        background: white;
        padding: 0.5rem 0;
        border-radius: 0 0 10px 10px;
    }
    .content__segment-body {
        flex: 1 0 auto;
        display: flex;
        background-color: white;
        swiper-container {
            min-height: 100%;
            width: 100%;
        }
    }
}

.content__minute-to-minute {
    width: 100%;
    display: block;
    position: relative;
    .content__minute-to-minute-item {
        width: 100%;
        position: relative;
        display: flex;
        margin: 1rem 0;
        &::before {
            content: "";
            display: block;
            position: absolute;
            top: 0;
            left: calc((65px * 0.5) - 2px);
            width: 4px;
            height: 100%;
            background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='black' stroke-width='4' stroke-dasharray='6%2c 14' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
            background-position: 100% 0;
            background-repeat: repeat-y;
            background-size: 40px 102%;
            opacity: 0.6;
        }
        .content__minute-to-minute-item-time {
            width: 65px;
            aspect-ratio: 1 / 1;
            position: relative;
            background-color: white;
            font-size: 16px;
            font-weight: 700;
            display: flex;
            align-items: center;
            justify-content: center;
            text-align: center;
            align-self: flex-start;
            box-shadow: 0 0 0px 5px white;
            &::before {
                content: "";
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background-color: rgba(var(--rgb-primary-color), 0.06);
                border-radius: 50%;
            }
        }
        .content__minute-to-minute-item-detail {
            flex: 1;
            padding: 1rem;
            
            .img__detail {
                width: auto;
                display: block;
                max-width: 100%;
                border-radius: 10px;
            }
            p {
                font-size: 16px;
                line-height: normal;
                display: block;
                margin: 0 0 1rem;
                font-weight: 400;
                small {
                    font-size: 80%;
                    margin: 0 0 0.5rem;
                }
            }
            .content__minute-to-minute-item-detail-title {
                display: block;
                h5 {
                    font-size: 16px;
                    font-weight: 900;
                    line-height: normal;
                    img {
                        display: inline-block;
                        width: 24px;
                        height: 24px;
                        vertical-align: middle;
                        object-fit: contain;
                        object-position: center;
                    }
                }
            }
            .content__minute-to-minute-item-detail-description {
                display: block;
                h5 {
                    font-size: 16px;
                    font-weight: 900;
                    line-height: normal;
                    img {
                        display: inline-block;
                        width: 24px;
                        height: 24px;
                        vertical-align: middle;
                        object-fit: contain;
                        object-position: center;
                    }
                }
                img {
                    width: auto;
                    max-width: 100%;
                    display: block;
                    margin: 0 auto;
                    border-radius: 10px;
                }
            }
        }
    }
}

.content__chat {
    .content__chat-item {
        width: 95%;
        position: relative;
        display: flex;
        margin: 1rem 0;
        padding: 0 1rem;
        &.type__me {
            margin-right: 0;
            margin-left: auto;
            .content__chat-item-detail {
                padding-left: 0;
                // padding-right: 1rem;
                text-align: right;
                .content__chat-item-detail-message {
                    background-color: var(--primary-color);
                    border-radius: 0.8rem 0.8rem 0 0.8rem;
                    color: white;
                    &::after {
                        text-align: left;
                    }
                }
            }
        }
        .content__chat-item-image {
            width: 45px;
            aspect-ratio: 1 / 1;
            position: relative;
            background-color: white;
            align-self: flex-start;
            border-radius: 50%;
            img,
            svg {
                width: 100%;
                height: 100%;
                display: block;
                object-fit: cover;
                object-position: center;
                border-radius: 50%;
            }
        }
        .content__chat-item-detail {
            flex: 1;
            padding-left: 1rem;
            text-align: left;
            h6 {
                font-size: 14px;
                font-weight: 700;
                display: block;
                margin: 0 0 0.4rem;
            }
            .content__chat-item-detail-message {
                width: auto;
                display: inline-block;
                padding: 0.8rem 1rem;
                background-color: rgba(var(--rgb-secondary-color), 0.05);
                border-radius: 0 0.8rem 0.8rem 0.8rem;
                margin-bottom: 1.5rem;
                position: relative;
                &::after {
                    content: attr(time);
                    position: absolute;
                    top: 100%;
                    right: 0;
                    text-align: right;
                    font-size: 12px;
                    color: black;
                    opacity: 0.3;
                    font-weight: 700;
                    margin-top: 0.2rem;
                    width: 100%;
                }
                p {
                    line-height: normal;
                    font-size: 16px;
                }
            }
        }
    }
}

.content__chat-input {
    display: flex;
    align-items: center;
    padding: 0.5rem;
    border-top: 1px solid #ddd;
    background-color: white;
    .content__chat-input-emojis {
        flex: 0 0 50px;
        display: inline-flex;
        align-items: center;
        justify-content: center;
    }
    .content__chat-input-field {
        flex: 1;
        display: inline-flex;
        textarea {
            width: 100%;
            max-width: 100%;
            min-width: 100%;
            color: black;
            height: 44px;
            min-height: fit-content;
            border: 0;
            background-color: #0000000a;
            border-radius: 8px;
            font-size: 14px;
            line-height: normal;
            resize: none;
            padding: 0.7rem 0.8rem;
            outline: none;
            box-shadow: none;
        }
    }
    .content__chat-input-actions {
        display: inline-flex;
        align-items: center;
        flex: 0 0 auto;
        padding-left: 8px;
        .btn__send {
            font-size: 1.5rem;
            width: 42px;
            height: 42px;
            min-height: auto;
            min-width: auto;
        }
    }
}

.content__trivia {
    padding: 1rem 0.8rem;
    background-color: var(--primary-color);
    color: white;
    border-radius: 1rem;
    display: flex;
    align-items: center;
    width: 600px;
    margin: 0 auto;
    max-width: 100%;
    .content__trivia-time {
        flex: 0 0 auto;
        text-align: center;
        min-width: 100px;
        h6 {
            font-size: 14px;
            font-weight: 600;
            line-height: normal;
            margin: 0;
            display: block;
            span {
                font-weight: 400;
            }
        }
        h3 {
            font-size: 32px;
            font-weight: 700;
            line-height: normal;
            margin: 0;
            display: block;
        }
    }
    .content__trivia-detail {
        flex: 1;
        padding-left: 0.8rem;
        h5 {
            font-size: 16px;
            font-weight: 600;
            line-height: normal;
            margin: 0;
            display: block;
        }
        p {
            font-size: 14px;
            line-height: normal;
            margin: 0;
            display: block;
        }
        .content__trivia-detail-actions {
            display: flex;
            justify-content: space-between;
            margin-top: 0.5rem;
            .btn__close {
                color: white;
                --color: white;
            }
            .btn__confirm {
                color: var(--secondary-color);
                --color: var(--secondary-color);
            }
        }
    }
}

.content__trivia-wrapper {
    width: 100%;
    display: block;
    padding: 0.5rem;
    .content__trivia-header {
        padding: 1rem 1rem 0;
        width: 100%;
        display: block;
        text-align: center;
        h4 {
            font-size: 18px;
            line-height: normal;
            margin: 0 0 0.5rem;
            font-weight: 600;
        }
        p {
            font-size: 14px;
            line-height: normal;
        }
    }
    .content__trivia-question {
        position: sticky;
        z-index: 2;
        top: 0;
        left: 0;
        width: 100%;
        background-color: var(--primary-color);
        padding: 1rem 0;
        .content__trivia-question-elm {
            display: flex;
            align-items: center;
            justify-content: center;
            text-align: center;
            padding: 1rem;
            aspect-ratio: 3 / 2;
            &.type__img {
                background-size: cover;
                background-position: center;
                border-radius: 1rem;
                width: 100%;
                display: block;
                position: relative;
                h2 {
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    padding: 2.5rem 0.5rem 1.5rem;
                    background: var(--primary-color);
                    background: linear-gradient(
                    0deg,
                    var(--primary-color) 5%,
                    rgba(var(--rgb-primary-color), 0.9) 30%,
                    rgba(var(--rgb-primary-color), 0.8) 45%,
                    rgba(255, 255, 255, 0) 100%
                    );
                }
            }
            &:not(.type__img) {
                background-color: rgba(255, 255, 255, 0.071);
                border-radius: 1rem;
            }
            h2 {
                display: block;
                margin: 0;
                width: 100%;
                text-align: center;
                font-size: 18px;
                color: white;
                font-weight: 600;
            }
        }
    }
    .content__trivia-question-time {
        width: 100%;
        display: flex;
        align-items: center;
        padding: 1rem 0;
        .content__trivia-question-time-label {
            width: 75px;
            text-align: center;
            font-size: 14px;
            line-height: normal;
            margin-right: 0.5rem;
        }
        .content__trivia-question-time-progressbar {
            flex: 1;
            ion-progress-bar {
                --progress-background: var(--success-color);
                border-radius: 3rem;
                overflow: hidden;
                --background: #ffffff40;
            }
        }
        .content__trivia-question-time-time {
            width: 75px;
            text-align: center;
            font-size: 12px;
            font-weight: 600;
            line-height: normal;
            background-color: white;
            color: black;
            border-radius: 0.8rem;
            padding: 0.1rem 0.2rem;
            margin-left: 0.5rem;
        }
    }
    .content__trivia-answers {
        width: 100%;
        display: block;
        .content__trivia-answers-item {
            display: flex;
            align-items: center;
            background-color: white;
            border-radius: 0.5rem;
            color: black;
            position: relative;
            margin-bottom: 0.8rem;
            overflow: hidden;
            &.animate__pulse {
                --animate-duration: 500ms;
            }
            &[disabled="true"] {
                opacity: 0.5 !important;
                pointer-events: none;
            }
            &.success {
                background-color: var(--success-color);
                color: white;
                opacity: 1 !important;
                .content__trivia-answers-item-option {
                    border-radius: 0 50% 50% 0;
                    background: white;
                    color: black;
                }
            }
            &.error {
                background-color: var(--error-color);
                color: white;
                opacity: 1 !important;
                pointer-events: auto;
                .content__trivia-answers-item-option {
                    border-radius: 0 50% 50% 0;
                    background: white;
                    color: black;
                }
            }
            .content__trivia-answers-item-option {
                flex: 0 0 50px;
                aspect-ratio: 1 / 1;
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: 18px;
                font-weight: 600;
            }
            .content__trivia-answers-item-answer {
                flex: 1;
                padding: 0.2rem 1rem;
                font-size: 16px;
                line-height: normal;
            }
        }
    }
}

.content__product-view {
    display: flex;
    flex-wrap: wrap;
    .content__product-img {
        width: 600px;
        max-width: 100%;
        background: white;
        .btns__swiper {
            position: absolute;
            top: 0;
            bottom: 0;
            margin: auto 0;
            height: fit-content;
            width: fit-content;
            z-index: 2;
            &.swiper-button-disabled {
                display: none;
            }
            &.btn__prev {
                left: 10px;
            }
            &.btn__next {
                right: 10px;
            }
        }
        swiper-container {
            --swiper-pagination-bullet-inactive-color: black;
            --swiper-pagination-color: var(--primary-color);
            &::part(pagination) {
                right: 10px;
                left: 10px;
                bottom: 0;
                width: calc(100% - (10px * 2));
            }
        }
        img {
            display: block;
            width: auto;
            max-width: 100%;
            margin: 0 auto;
        }
    }
    .content__product-detail {
        flex: 1;
        padding: 1rem;
        .content__product-detail-name {
            font-size: 26px;
            font-weight: 600;
            line-height: normal;
            margin: 0 0 10px;
        }
        .content__product-detail-id {
            font-size: 16px;
            font-weight: 400;
            color: #4b4b4b;
            margin: 0 0 15px;
            line-height: normal;
            display: block;
        }
        .content__product-detail-section {
            width: 100%;
            &.type__card {
                padding: 1rem 1.5rem;
                border: 1px solid #ddd;
                border-radius: 18px;
            }
            .content__product-detail-section-title {
                font-size: 20px;
                font-weight: 600;
                line-height: normal;
                margin: 0 0 8px -0.5rem;
                color: var(--secondary-color);
            }
            p,
            .content__product-detail-decription {
                font-size: 16px;
                font-weight: 400;
                line-height: normal;
                margin: 0 0 8px;
            }
        }
        .content__product-detail-price {
            font-size: 16px;
            font-weight: 600;
            line-height: normal;
            span {
                color: var(--primary-color);
                vertical-align: middle;
            }
            del {
                color: #8b8b8b;
                font-size: 80%;
                vertical-align: middle;
            }
            .badge {
                background-color: var(--error-color);
            }
        }
        .content__product-detail-sizes {
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            .content__product-detail-size {
                border: 1px solid #ddd;
                border-radius: 10px;
                padding: 6px 15px;
                line-height: normal;
                font-size: 16px;
                font-weight: 500;
                cursor: pointer;
                transition: all ease 0.26s;
                margin: 5px;
                user-select: none;
                &:hover {
                    border-color: var(--primary-color);
                    background-color: var(--primary-color);
                    color: white;
                }
                &:has(:checked) {
                    border-color: var(--primary-color);
                    background-color: var(--primary-color);
                    color: white;
                }
                input {
                    display: none;
                }
            }
        }
        .content__product-detail-add-cart {
            display: inline-block;
            padding: 1rem;
            border: 1px solid #ddd;
            border-radius: 18px;
            .content__product-detail-add-cart-body {
                display: inline-flex;
                flex-wrap: wrap;
                align-items: center;
                .content__product-detail-add-cart-body-quantity {
                    display: inline-flex;
                    flex-wrap: wrap;
                    align-items: center;
                }
                h5 {
                    margin: 5px 10px;
                    font-size: 16px;
                    font-weight: 600;
                    line-height: normal;
                    color: var(--secondary-color);
                }
                input {
                    width: 60px;
                    margin: 0 8px;
                    appearance: none;
                    display: inline-block;
                    min-width: unset;
                    border: 1px solid #ddd;
                    border-radius: 8px;
                    text-align: center;
                    padding: 8px;
                }
            }
        }
    }
}

.badge {
    display: inline-block;
    padding: 6px 10px;
    background-color: var(--primary-color);
    color: white;
    border-radius: 8px;
    margin: 2px 10px;
}

.content__notice-modal {
    h5 {
        font-size: 18px;
        color: black;
        line-height: normal;
        margin: 0 0 0.5rem;
        display: block;
        font-weight: 600;
    }
    .content__text {
        margin: 0 0 0.5rem;
        display: block;
        font-weight: 400;
        font-size: 14px;
        line-height: 22px;
        color: black;
    }
}

.content__title-banner {
    width: 100%;
    aspect-ratio: 5 / 2;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--primary-color);
    padding: 1rem;
    &.type__icon {
        background-color: rgba(var(--rgb-primary-color), 0.05);
        img {
            width: 80px;
        }
    }
}

.content__service-discount {
    display: block;
    width: 100%;
    position: relative;
    .content__service-discount-info {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        position: relative;
        background: white;
        border-radius: 1rem;
        padding: 1rem;
        .content__service-discount-detail,
        .content__service-discount-image {
            flex: 1 0 50%;
            padding: 1rem;
        }
        .content__service-discount-image {
            position: relative;
            padding-left: 4rem;
            aspect-ratio: 2 / 1;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: rgba(0, 0, 0, 0.02);
            border-radius: 1rem;
            img {
                width: auto;
                display: block;
                max-width: 100%;
                margin: 0 auto;
            }
            .content__service-discount-discount {
                position: absolute;
                top: 50%;
                left: 0;
                transform: translate(-50%, -50%);
                width: 100px;
                aspect-ratio: 1 / 1;
                svg {
                    width: 100%;
                    height: 100%;
                    display: block;
                    margin: 0;
                }
            }
        }
        .content__service-discount-detail {
            padding-right: 4rem;
            color: black;
            .content__service-discount-detail-text {
                font-size: 16px;
                line-height: 24px;
                margin: 0;
                display: block;
                width: 100%;
            }
            .content__service-discount-detail-categories {
                width: 100%;
                display: block;
                margin: 0.5rem 0;
                span {
                    display: inline-block;
                    padding: 5px 10px;
                    border-radius: 10px;
                    background-color: rgba(var(--rgb-primary-color), 0.06);
                    color: black;
                    font-size: 16px;
                    margin: 2px;
                }
            }
            h3 {
                font-size: 38px;
                font-weight: 700;
                line-height: normal;
                margin: 1rem 0;
            }
            h5 {
                font-size: 18px;
                font-weight: 600;
                line-height: normal;
                margin: 0.8rem 0;
            }
            p {
                font-size: 16px;
                line-height: 24px;
            }
        }
    }
    .content__service-discount-more-information {
        width: 100%;
        display: block;
        h4 {
            font-size: 26px;
            font-weight: 600;
            display: block;
            width: 100%;
            text-align: center;
            line-height: normal;
            margin: 0 0 1rem;
            color: var(--primary-color);
        }
        p.subtext {
            text-align: center;
            font-size: 16px;
            font-weight: 500;
            line-height: 24px;
            display: block;
            margin: 0;
        }
        .content__service-discount-more-information-item {
            padding: 2rem;
            background-color: white;
            border-radius: 1rem;
            width: 100%;
            display: block;
            text-align: left;
            img {
                display: block;
                margin: 0 auto;
                max-width: 100%;
                max-height: 300px;
            }
            h5 {
                display: block;
                font-size: 20px;
                font-weight: 600;
                text-align: center;
                color: var(--primary-color);
                margin-bottom: 1rem;
                padding-bottom: 1rem;
                border-bottom: 1px solid #ddd;
            }
            .content__service-discount-more-information-item-details {
                width: 100%;
                .content__service-discount-more-information-item-detail {
                    width: 100%;
                    display: flex;
                    &:not(:last-child) {
                        margin-bottom: 1rem;
                    }
                    span {
                        width: 26px;
                        height: 26px;
                        margin-right: 10px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        border-radius: 50%;
                        background-color: var(--secondary-color);
                        color: white;
                        font-size: 14px;
                    }
                    div {
                        flex: 1;
                        font-size: 16px;
                        line-height: 22px;
                        font-weight: 400;
                        color: black;
                        margin: 0;
                        p {
                            font-size: 16px;
                            line-height: 22px;
                            font-weight: 400;
                            color: black;
                            margin: 0 0 8px;
                        }
                    }
                }
            }
        }
    }
}

.header__sticky {
    position: sticky;
    top: 0;
    width: 100%;
    z-index: 1;
    background-color: var(--bg-header-sticky, white);
}

.content__next-matchs-item {
    width: 100%;
    display: block;
    padding: 1rem;
    background-color: white;
    border-radius: 10px;
    .content__next-matchs-item-detail {
        width: 100%;
        display: block;
        text-align: center;
        h6 {
            font-family: var(--font-title);
            font-size: 12px;
            font-weight: 600;
            line-height: normal;
            text-align: center;
            margin-bottom: 5px;
        }
        h5 {
            font-family: var(--font-text);
            font-size: 14px;
            font-weight: 400;
            line-height: normal;
            text-align: center;
            margin-bottom: 5px;
        }
    }
    .content__next-matchs-item-teams {
        width: 100%;
        display: flex;
        align-items: center;
    }
    .content__next-matchs-item-teams-a,
    .content__next-matchs-item-teams-b {
        flex: 1;
        .content__next-matchs-item-teams-img {
            width: 40px;
            aspect-ratio: 1 / 1;
            max-width: 100%;
            margin: 0;
            margin-bottom: 10px;
            img,
            svg {
                width: 100%;
                height: 100%;
                object-fit: scale-down;
                object-position: center;
            }
        }
        h4 {
            text-align: left;
            font-size: 14px;
            font-weight: 600;
            line-height: normal;
        }
    }
    .content__next-matchs-item-teams-a {
        padding-right: 10px;
    }
    .content__next-matchs-item-teams-b {
        padding-left: 10px;
        .content__next-matchs-item-teams-img {
            margin-left: auto;
        }
        h4 {
            text-align: right;
        }
    }
    .content__next-matchs-item-teams-points {
        width: 94px;
        padding: 10px;
        text-align: center;
        font-size: 16px;
        font-weight: 600;
        line-height: normal;
    }
    .content__next-matchs-item-minutetominute {
        margin-top: 1rem;
        border-top: 1px solid rgba(0, 0, 0, 0.2);
    }
}

.content__positions-table {
    width: 100%;
    display: block;
    max-width: 100%;
    max-height: 500px;
    overflow: auto;
    table {
        min-width: 100%;
        tr {
            text-align: center;
            color: black;
            th {
                background-color: #f9fafb;
                color: #6b7280;
                white-space: nowrap;
                padding: 10px;
                line-height: normal;
                font-size: 12px;
                font-weight: 400;
                text-transform: uppercase;
                &.content__positions-table-bg {
                    background-color: rgba(var(--rgb-primary-color), 0.8);
                    color: white;
                }
            }
            td {
                white-space: nowrap;
                padding: 10px;
                line-height: normal;
                font-size: 12px;
                font-weight: 500;
                text-transform: uppercase;
                background-color: white;
                &.content__positions-table-bg {
                    background-color: rgba(var(--rgb-primary-color), 0.8);
                    color: white;
                    font-weight: 400;
                }
                .content__positions-table-team {
                    display: flex;
                    align-items: center;
                    width: fit-content;
                    min-width: 100px;
                    img {
                        width: 25px;
                        height: 25px;
                        object-fit: scale-down;
                        object-position: center;
                    }
                    span {
                        display: inline-block;
                    }
                }
            }
        }
    }
}

.content__positions-ctt {
    width: 100%;
    text-align: center;
    padding: 1rem;
    border-radius: 10px;
    background: white;
    margin-bottom: 0.8rem;
    h5 {
        font-size: 14px;
        font-weight: 400;
        line-height: normal;
    }
    h3 {
        font-size: 24px;
        font-weight: 800;
        line-height: 1.2;
        margin-bottom: 0.8rem;
    }
    .content__positions-ctt-detail {
        width: 100%;
        .content__positions-ctt-detail-ctt {
            display: flex;
            align-items: center;
            margin-bottom: 6px;
            img {
                width: 60px;
                aspect-ratio: 3 / 2;
                object-fit: cover;
                object-position: center;
                border-radius: 6px;
            }
            .content__positions-ctt-detail-ctt-fan {
                flex: 1;
                display: flex;
                align-items: center;
                padding: 0 0.5rem;
                img {
                    width: 30px;
                    aspect-ratio: 1 / 1;
                    object-fit: scale-down;
                    border-radius: 0px;
                }
                h5 {
                    flex: 1;
                    text-align: left;
                    font-size: 14px;
                    line-height: 1.2;
                    padding-left: 5px;
                    b {
                        min-width: 32px;
                        display: inline-block;
                    }
                }
            }
        }
    }
}

.content__videos-community {
    display: block;
    width: 100%;
    .content__video {
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        width: 100%;
        aspect-ratio: 3 / 4;
        border-radius: 0.8rem;
        position: relative;
        margin-bottom: 0.5rem;
        .bg__video {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            border-radius: 0.8rem;
            background-color: rgba(0, 0, 0, 0.3);
            z-index: 1;
        }
        .content__video-detail {
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            pointer-events: none;
            z-index: 2;
            .content__video-detail-actions {
                display: flex;
                flex-direction: column;
                align-items: center;
                width: 50px;
                margin-left: auto;
                .cy__btn {
                    font-size: 2.2rem;
                    color: rgba(255, 255, 255, 0.6);
                    pointer-events: auto;
                    .inner__btn {
                        flex-direction: column;
                        .text__btn {
                            font-size: 12px;
                            display: block;
                            width: 100%;
                            text-align: center;
                        }
                    }
                }
            }
            .content__video-detail-info {
                width: 100%;
                padding: 0.8rem;
                color: white;
                text-shadow: 0 0 black;
                h5 {
                    line-height: normal;
                    font-size: 16px;
                    font-weight: 500;
                    margin-bottom: 5px;
                }
                p,
                .content__video-detail-info-text {
                    font-size: 14px;
                    line-height: 1.5;
                    font-weight: 300;
                }
            }
        }
        .content__video-detail-header {
            width: 100%;
            display: flex;
            align-items: center;
            position: absolute;
            top: 0;
            left: 0;
            z-index: 3;
            padding: 0.5rem;
            img {
                width: 45px;
                height: 45px;
                object-fit: cover;
                object-position: center;
                border-radius: 4px;
                overflow: hidden;
            }
            .content__video-detail-header-detail {
                flex: 1;
                padding: 5px;
                color: white;
                text-shadow: black;
                h5 {
                    font-size: 16px;
                    font-weight: 600;
                    line-height: normal;
                }
                p {
                    font-size: 12px;
                    font-weight: 400;
                    line-height: normal;
                }
            }
        }
    }
}

.content__scroll-video {
    width: 100%;
    height: 100%;
    display: block;
    background-color: black;
    .content__scroll-video-back {
        position: absolute;
        top: 10px;
        left: 0;
        z-index: 9;
        width: 100%;
        padding: 0 10px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        pointer-events: none;
        a,
        ion-button {
            pointer-events: auto;
        }
    }
    swiper-container {
        width: 100%;
        height: 100%;
        .content__video-community-play {
            width: 100vw;
            height: 100vh;
            position: relative;
            background: black;
            min-width: 100vw;
            min-height: 100vh;
            video {
                width: 100%;
                height: 100%;
                object-fit: scale-down;
            }
            .content__video-bg {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                z-index: 1;
            }
            .content__video-detail {
                position: absolute;
                bottom: 0;
                left: 0;
                width: 100%;
                pointer-events: none;
                z-index: 2;
                .content__video-detail-actions {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    width: 50px;
                    margin-left: auto;
                    ion-avatar {
                        width: 40px;
                        max-width: 100%;
                        margin: 0 auto;
                        pointer-events: auto;
                    }
                    .cy__btn {
                        font-size: 2.2rem;
                        color: rgba(255, 255, 255, 0.6);
                        pointer-events: auto;
                        .inner__btn {
                            flex-direction: column;
                            .text__btn {
                                font-size: 12px;
                                display: block;
                                width: 100%;
                                text-align: center;
                            }
                        }
                    }
                }
                .content__video-detail-info {
                    width: 100%;
                    padding: 0.8rem;
                    color: white;
                    text-shadow: 0 0 black;
                    h5 {
                        line-height: normal;
                        font-size: 16px;
                        font-weight: 500;
                        margin-bottom: 5px;
                    }
                    p {
                        font-size: 14px;
                        line-height: 1.5;
                        font-weight: 300;
                    }
                }
            }
            .content__video-detail-header {
                width: 100%;
                display: flex;
                align-items: center;
                position: absolute;
                top: 0;
                left: 0;
                z-index: 3;
                padding: 0.5rem;
                pointer-events: none;
                img {
                    width: 45px;
                    height: 45px;
                    object-fit: cover;
                    object-position: center;
                    border-radius: 4px;
                    overflow: hidden;
                }
                .content__video-detail-header-detail {
                    flex: 1;
                    padding: 5px;
                    color: white;
                    text-shadow: black;
                    h5 {
                        font-size: 16px;
                        font-weight: 600;
                        line-height: normal;
                    }
                    p {
                        font-size: 12px;
                        font-weight: 400;
                        line-height: normal;
                    }
                }
            }
        }
    }
    .reels__nodata {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        color: white;
        img {
            opacity: 0.4;
            width: auto;
            max-width: 100%;
        }
    }
}

.content__comments {
    width: 100%;
    .content__comments-item {
        width: 100%;
        display: flex;
        margin-bottom: 0.8rem;
        .content__comments-item-img {
            width: 40px;
            display: block;
            border-radius: 50%;
            img {
                max-width: 100%;
                border-radius: 50%;
                display: block;
                aspect-ratio: 1 / 1;
                object-fit: cover;
                object-position: center;
            }
        }
        .content__comments-item-detail {
            flex: 1;
            padding-left: 10px;
            color: black;
            h5 {
                font-size: 14px;
                line-height: 1.2;
                margin: 0 0 0.1rem;
                display: block;
                font-weight: 600;
                span {
                    font-weight: 400;
                    opacity: 0.5;
                    font-size: 78%;
                }
            }
            p {
                font-size: 12px;
                line-height: 1.4;
                margin: 0;
                display: block;
            }
            .content__comments-item-detail-comments {
                margin-top: 0.1rem;
                line-height: 1;
                width: 100%;
                display: block;
                &:has(.content__comments-item) {
                    margin-top: 0.5rem;
                }
                .content__comments-item-detail-comments-response {
                    font-size: 12px;
                    line-height: normal;
                    display: inline-block;
                }
                ion-accordion {
                    width: 100%;
                    ion-item {
                        max-width: max-content;
                        min-height: auto;
                        white-space: nowrap;
                        font-size: 12px;
                        --width: auto;
                        --min-width: auto;
                        --min-height: auto;
                        --max-width: auto;
                        --padding-start: 6px;
                        --inner-padding-end: 6px;
                        --padding-top: 2px;
                        --padding-bottom: 2px;
                        --border-color: transparent;
                        --inner-border-width: 0px;
                        --border-width: 0px;
                        --background: transparent;
                        --ion-color-base: transparent !important;
                        --border-radius: 3rem;
                        ion-label {
                            margin: 0;
                            display: flex;
                            align-items: center;
                            span {
                                width: 25px;
                                display: block;
                                height: 1px;
                                background: black;
                                opacity: 0.2;
                                margin-right: 5px;
                            }
                        }
                        ion-icon {
                            margin-top: 0;
                            margin-bottom: 0;
                        }
                    }
                }
                .content__comments-item-detail-comments-content {
                    margin-top: 1rem;
                    .content__comments-item {
                        .content__comments-item-img {
                            width: 30px;
                        }
                    }
                }
            }
            .content__comments-item-detail-comments-response {
                position: relative;
                border-radius: 3rem;
                padding: 2px 6px;
                overflow: hidden;
            }
        }
    }
}

.content__data-content {
    width: 100%;
    display: block;
    position: relative;
    .content__data-content-title {
        font-size: 18px;
        font-weight: 600;
        line-height: 27px;
        padding: 1rem 1rem 0;
    }
    .content__data-content-subtitle {
        font-size: 16px;
        font-weight: 600;
        line-height: 24px;
        padding: 1rem;
    }
    .content__data {
        padding: 1rem;
        width: 100%;
        display: block;
        font-size: 16px;
        line-height: 24px;
        font-weight: 400;
        color: #6b7280;
        h2 {
            font-size: 25px;
            font-weight: 700;
            line-height: 32px;
            margin: 0 0 15px;
        }
        h3 {
            font-size: 21px;
            font-weight: 600;
            line-height: 29px;
            margin: 0 0 10px;
        }
        h4 {
            font-size: 18px;
            font-weight: 600;
            line-height: 27px;
            margin: 0 0 10px;
        }
        h5 {
            font-size: 16px;
            font-weight: 600;
            line-height: 24px;
            margin: 0 0 10px;
        }
        p {
            font-size: 16px;
            font-weight: 400;
            line-height: 24px;
            margin: 0 0 10px;
        }
        img {
            width: auto;
            max-width: 100%;
        }
    }
}

.card__options {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    .card__options-item-col {
        flex: 1 0 100%;
        width: 100%;
        padding: 0.5rem;
    }
    .card__options-item {
        width: auto;
        max-width: 100%;
        display: flex;
        align-items: center;
        padding: 1rem;
        background-color: white;
        border-radius: 1rem;
        .card__options-item-icon {
            width: calc(50px + 1rem);
            aspect-ratio: 1 / 1;
            position: relative;
            display: block;
            padding-right: 1rem;
            .card__options-item-icon-ctt {
                display: block;
                width: 100%;
                height: 100%;
                background-size: contain;
                background-repeat: no-repeat;
                background-position: center;
                &.type__mask {
                    mask-size: contain;
                    mask-repeat: no-repeat;
                    mask-position: center;
                }
            }
        }
        .card__options-item-detail {
            flex: 1;
            h5 {
                font-size: 16px;
                font-weight: 600;
                line-height: normal;
                margin: 0 0 5px;
            }
            p {
                font-size: 14px;
                font-weight: 400;
                line-height: normal;
                margin: 0;
            }
        }
        .card__options-item-to {
            width: calc(30px + 1rem);
            padding-left: 1rem;
            position: relative;
            display: block;
            text-align: center;
            color: var(--primary-color);
            font-size: 16px;
        }
    }
}

&.camera-active {
    --ion-background-color: transparent;
    background: transparent;
    app-upload-video {
        .content__header-upload-video {
            transform: translateY(-100vh);
        }
        ion-content {
            --overflow: hidden;
            --background: transparent;
            .content__form-upload-video {
                transform: translateY(-100%);
            }
            .btn__close-camera, .btn__start-recording {
                opacity: 1;
                visibility: visible;
                pointer-events: auto;
            }
        }
    }
}

app-upload-video {
    .content__header-upload-video, ion-content .content__form-upload-video {
        transition: all cubic-bezier(0.18, 0.71, 0.34, 1.09) 0.5s;
    }
}

.content__calendar {
    span.p-calendar {
        width: 600px;
        max-width: 100%;
        display: block;
        margin: 0 auto;
        div.p-datepicker {
            padding: 0;
            .p-datepicker-header {
                .p-datepicker-title button {
                    margin: 5px;
                }
            }
            .p-datepicker-calendar-container {
                table {
                    thead {
                        tr {
                            border-bottom: 6px solid rgba(221, 221, 221, 0.5);
                            th {
                                padding: 10px;
                                color: rgba(var(--rgb-primary-color), 0.5);
                            }
                        }
                    }
                    tbody {
                        td {
                            position: relative;
                            &::before {
                                content: "";
                                display: block;
                                padding-bottom: 80%;
                            }
                            & > span {
                                position: absolute;
                                top: 50%;
                                left: 50%;
                                transform: translate(-50%, -50%);
                            }
                        }
                    }
                }
            }
        }
        span.p-element {
            overflow: unset;
            width: 45px;
            height: 45px;
            padding: 2.5px;
            transition: all ease-in-out 0.26s;
            font-weight: 600;
            &.p-disabled {
                font-weight: 400;
                opacity: 0.3;
            }
            &.p-datepicker-current-day {
                box-shadow: 0 0 1px 4px var(--secondary-color);
                color: var(--secondary-color);
                background-color: transparent;
            }
            .custom_day {
                position: relative;
                display: inline-flex;
                justify-content: center;
                align-items: center;
                text-align: center;
                width: 100%;
                height: 100%;
                background: transparent !important;
                img {
                    position: absolute;
                    width: 130%;
                    aspect-ratio: 1 / 1;
                    object-fit: contain;
                    object-position: center;
                    max-width: unset;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    &.sub_image {
                        position: absolute;
                        top: unset;
                        left: unset;
                        bottom: 0;
                        right: 0;
                        transform: translate(50%, 50%);
                        width: 65%;
                        border-radius: 50%;
                        background-color: rgba(var(--rgb-primary-color), 0.6);
                    }
                }
            }
        }
    }
}

/* HTML: <div class="loader"></div> */
.loader {
    width: 50px;
    aspect-ratio: 1;
    border-radius: 50%;
    background: 
      radial-gradient(farthest-side, var(--primary-color) 94%,rgba(var(--rgb-primary-color), 0.1)) top/8px 8px no-repeat,
      conic-gradient(rgba(var(--rgb-primary-color), 0.1) 30%, var(--primary-color));
    -webkit-mask: radial-gradient(farthest-side, transparent calc(100% - 8px), var(--primary-color) 0);
    mask: radial-gradient(farthest-side, transparent calc(100% - 8px), var(--primary-color) 0);
    animation: loading13 1s infinite linear;
}
@keyframes loading13 { 
    100% {
        transform: rotate(1turn)
    }
}

.content__team-lineup {
    width: 100%;
    border: 1px solid #ddd;
    border-radius: 0.8rem;
    margin: 0 0 1rem;
    padding: 0.8rem 1rem;
    h4 {
        font-size: 16px;
        font-weight: 700;
        line-height: normal;
        margin-bottom: 1rem;
    }
}

&:has(.footer__menu-2) {
    ion-router-outlet ion-content.content__custom {
        --padding-bottom: 15px;
    }
}
// Menu footer
.footer__menu-2 {
    --duration: .45s;
    --cubic: cubic-bezier(0.4, 0, 0.2, 1);
    --color-default: #b8b8b8;
    --color-active: var(--primary-color);
    --badge-color: rgba(var(--rgb-tertiary-color), 0.1);
    filter: drop-shadow(0px 0px 4px rgba(0, 0, 0, 0.2));
    width: 500px;
    max-width: 100%;
    display: block;
    margin: 0 auto;
    margin-top: -12px;
    ion-toolbar {
        --background: transparent;
        .menu__2 {
            width: 100%;
            position: relative;
            display: flex;
            height: 66px;
            user-select: none;
            position: relative;
            align-items: center;
            padding: 0 10px 15px;
            justify-content: center;
            background-color: #fefefe;
            border-radius: 10px 10px 0 0;
            -webkit-tap-highlight-color: transparent;
            .menu__item {
                all: unset;
                flex-grow: 1;
                cursor: pointer;
                overflow: hidden;
                padding-top: 5px;
                position: relative;
                color: var(--color-default);
                display: flex;
                align-items: center;
                justify-content: center;
                transition: flex-grow var(--duration) var(--cubic);
                &.active {
                    flex-grow: 2.7;
                    color: var(--color-active);
                }
                                
                &.active .menu__icon::before {
                    transform: scale(1);
                }
                
                &.active .menu__subitem::after {
                    transform: translate3d(21px, 0, 0) scaleX(1);
                    transition: transform var(--duration) var(--cubic);
                }

                &.active .menu__icon {
                    transform: translate3d(-95% , 0, 0);
                    .icon {
                        animation: iconMenuHorizontal var(--duration-icon);
                    }
                }

                .menu__subitem {
                    display: inline-flex;
                    align-items: center;
                    justify-content: center;
                    transition: flex-grow var(--duration) var(--cubic);
                    position: relative;
                    &::after {
                        left: -60%;
                        bottom: 0;
                        content: " ";
                        height: 2.5px;
                        position: absolute;
                        border-radius: 20px;
                        transform-origin: left center;
                        background-color: currentColor;
                        width: calc( var(--lineWidth) + 5px );
                        transform: translate3d(28px , 0, 0) scaleX(0);
                        transition: transform calc( var(--duration) + .2s) var(--cubic);
                    }
                }

                .menu__icon {
                    font-size: 22px;
                    color: currentColor;
                    transition: transform var(--duration) var(--cubic);
                    position: relative;
                    &::before {
                        top: -10%;
                        left: -18%;
                        width: 15px;
                        height: 15px;
                        content: " ";
                        position: absolute;
                        border-radius: 50%;
                        transform: scale(0);
                        transform-origin: center;
                        background-color: var(--badge-color);
                        transition: transform var(--duration) var(--cubic);
                    }
                    .icon {
                        --duration-icon: 1s;
                        width: 1.2em;
                        height: 1.2em;
                        display: block;
                    }
                }

                .menu__text {
                    left: 18%;
                    font-size: 14px;
                    position: absolute;
                    text-transform: capitalize;
                    letter-spacing: .01em;
                    transform: translate3d(0, 109%, 0);
                    transition: transform calc( var(--duration) / 3.7 );
                    &.active {
                        transform: translate3d(0, 0, 0);
                        transition: transform calc(var(--duration) / 1.5);
                    }
                }
            }
        }
        
        @keyframes iconMenuHorizontal {
            25% {
                transform: translate3d(0, -7px, 0);
            }
            50% {
                transform: translate3d(0, 4px, 0);
            }
        }
    }
}