// sm Tailwind
@media screen and (min-width: 640px) {
    & {
        --size-responsive: 4px;
    }
    .featured__product-home-grid {
        display: grid;
        grid-template-columns: repeat(2, minmax(0, 1fr));
    }
    
    .content__pad {
        padding: 1rem;
    }
    
    .content__next-match-home {
        .content__left {
            width: 140px;
        }
    }
    
    .content__benefits .content__benefits-grid {
        grid-template-columns: repeat(auto-fill, minmax(160px, 1fr));
    }
    
    .userCustomTitle .userCustomTitle-detail {
        display: flex;
        flex-direction: column;
        small i {
            margin-left: 8px;
        }
    }
    
    .content__product-view .content__product-detail {
        padding: 1rem 2rem;
    }
}

@media screen and (min-width: 361px) and (max-width: 767px) {
}

// md tailwind
@media screen and (min-width: 768px) {
    swal2-popup.swal2-modal.alert_table-swal ctt_table table {
        width: 100%;
    }
    .featured__product-home-grid {
        display: grid;
        grid-template-columns: repeat(3, minmax(0, 1fr));
    }
    .featured__product-home {
        .featured__product-detail {
            width: auto;
            flex: 1;
            display: flex;
            flex-direction: column;
            justify-content: center;
        }
    }
}

// lg tailwind
@media screen and (min-width: 992px) {
    .featured__product-home-grid {
        display: grid;
        grid-template-columns: repeat(4, minmax(0, 1fr));
    }
}

@media screen and (min-width: 1024px) {
}

@media screen and (max-width: 1279px) {
    .content__product-view .content__product-img {
        width: 400px;
    }
}

@media screen and (max-width: 991px) {
    .content__product-view .content__product-img {
        width: 350px;
    }
    .content__service-discount .content__service-discount-info {
        .content__service-discount-detail,
        .content__service-discount-image {
            width: 100%;
            flex: none;
        }
        .content__service-discount-detail {
            padding-right: 1rem;
            padding-top: 4rem;
        }
        .content__service-discount-image {
            order: -1;
            padding-left: 1rem;
            padding-bottom: 4rem;
            .content__service-discount-discount {
                top: 100%;
                left: 50%;
                svg text {
                    font-size: 12rem;
                }
            }
        }
    }
}

@media screen and (max-width: 767px) {
    .content__product-view {
        .content__product-img {
            width: 100%;
        }
        .content__product-detail .content__product-detail-name {
            font-size: 18px;
        }
    }
    
    .content__service-discount {
        .content__service-discount-info {
            .content__service-discount-detail {
                .content__service-discount-detail-categories span {
                    font-size: 12px;
                }
                h3 {
                    font-size: 22px;
                }
                h5 {
                    font-size: 16px;
                }
                p {
                    font-size: 14px;
                    line-height: 20px;
                }
            }
            .content__service-discount-image {
                order: -1;
                padding-left: 1rem;
                padding-bottom: 4rem;
                .content__service-discount-discount {
                    width: 80px;
                }
            }
        }
        .content__service-discount-more-information {
            h4 {
                font-size: 20px;
            }
            p.subtext {
                font-size: 14px;
            }
            .content__service-discount-more-information-item {
                h5 {
                    font-size: 18px;
                }
                .content__service-discount-more-information-item-details
                .content__service-discount-more-information-item-detail
                div
                p {
                    font-size: 14px;
                    line-height: 20px;
                }
            }
        }
    }
}

@media screen and (max-width: 639px) {
    .content__calendar span.p-calendar {
        div.p-datepicker .p-datepicker-calendar-container table tbody td {
            font-size: 14px;
            &::before {
                padding-bottom: 100%;
            }
        }
        span.p-element {
            .custom_day img {
                width: 115%;
            }
            &.p-datepicker-current-day {
                box-shadow: 0 0 1px 3px var(--secondary-color);
            }
        }
    }
}

@media screen and (max-width: 512px) {
}

@media screen and (min-width: 992px) {
}

@media screen and (min-width: 1280px) {
}

@media screen and (min-width: 1350px) {
}

@media screen and (min-width: 1536px) {
}