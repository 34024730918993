:root {
  --primary-color: #{$primary-color};
  --secondary-color: #{$secondary-color};
  --tertiary-color: #{$tertiary-color};
  // * for RGBA
  --rgb-primary-color: #{$rgb-primary-color}; //--primary-color *
  --rgb-secondary-color: #{$rgb-secondary-color}; //--secondary-color *
  --rgb-tertiary-color: #{$rgb-tertiary-color}; //--tertiary-color *
}

:root {
  /** primary **/
  --ion-color-primary: #{$primary-color};
  --ion-color-primary-rgb: #{$rgb-primary-color};
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255, 255, 255;
  --ion-color-primary-shade: #{lighten($primary-color, 5%)};
  --ion-color-primary-tint: #{darken($primary-color, 5%)};

  /** secondary **/
  --ion-color-secondary: #{$secondary-color};
  --ion-color-secondary-rgb: #{$rgb-secondary-color};
  --ion-color-secondary-contrast: #ffffff;
  --ion-color-secondary-contrast-rgb: 255,255,255;
  --ion-color-secondary-shade: #{lighten($secondary-color, 5%)};
  --ion-color-secondary-tint: #{darken($secondary-color, 5%)};

  /** tertiary **/
  --ion-color-tertiary: #{$tertiary-color};
  --ion-color-tertiary-rgb: #{$rgb-tertiary-color};
  --ion-color-tertiary-contrast: #ffffff;
  --ion-color-tertiary-contrast-rgb: 255,255,255;
  --ion-color-tertiary-shade: #{lighten($tertiary-color, 5%)};
  --ion-color-tertiary-tint: #{darken($tertiary-color, 5%)};

  /** success **/
  --ion-color-success: #2dd36f;
  --ion-color-success-rgb: 45, 211, 111;
  --ion-color-success-contrast: #ffffff;
  --ion-color-success-contrast-rgb: 255, 255, 255;
  --ion-color-success-shade: #28ba62;
  --ion-color-success-tint: #42d77d;

  /** warning **/
  --ion-color-warning: #ffc409;
  --ion-color-warning-rgb: 255, 196, 9;
  --ion-color-warning-contrast: #000000;
  --ion-color-warning-contrast-rgb: 0, 0, 0;
  --ion-color-warning-shade: #e0ac08;
  --ion-color-warning-tint: #ffca22;

  /** danger **/
  --ion-color-danger: #eb445a;
  --ion-color-danger-rgb: 235, 68, 90;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #cf3c4f;
  --ion-color-danger-tint: #ed576b;

  /** dark **/
  --ion-color-dark: #222428;
  --ion-color-dark-rgb: 34, 36, 40;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255, 255, 255;
  --ion-color-dark-shade: #1e2023;
  --ion-color-dark-tint: #383a3e;

  /** medium **/
  --ion-color-medium: #92949c;
  --ion-color-medium-rgb: 146, 148, 156;
  --ion-color-medium-contrast: #ffffff;
  --ion-color-medium-contrast-rgb: 255, 255, 255;
  --ion-color-medium-shade: #808289;
  --ion-color-medium-tint: #9d9fa6;

  /** light **/
  --ion-color-light: #f4f5f8;
  --ion-color-light-rgb: 244, 245, 248;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0, 0, 0;
  --ion-color-light-shade: #d7d8da;
  --ion-color-light-tint: #f5f6f9;
}